<template>
    <div class="footer">
        <footer class="foot">
            Sacry Design
        </footer>
    </div>
</template>
<script>

</script>
<style scoped>
.foot {
    color:white;
    padding-top: 1.5rem;
}
.footer {
    background: black;
    height: 4rem;
}
</style>